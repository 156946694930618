<script>
  import { error_message } from "$lib/store.js";
  import { _ } from "svelte-i18n";
  import { fly } from "svelte/transition";

  let visible = false;

  $: if ($error_message) {
    window._paq.push(["trackEvent", "error", $error_message]);
    visible = true;
    // Auto-hide after 5 seconds (optional)
    // setTimeout(() => {
    //   visible = false;
    //   error_message.set(null);
    // }, 5000);
  }

  function reloadPage() {
    visible = false;
    error_message.set(null);
    location.reload();
  }
</script>

<!-- ---------------------------------------------------------------------- -->

{#if visible}
  <div class="error">
    <div class="error-overlay" transition:fly={{ y: 20, duration: 300 }}>
      <h1>{$_("error-message1")}</h1>
      <p>
        {$_("error-message2")}
      </p>
      <p>{$error_message}</p>
      <p>
        <a href="/">{$_("error-message3")}</a>
      </p>
      <button title={$_("reload")} class="reload-button" on:click={reloadPage} on:keypress={reloadPage} />
    </div>
  </div>
{/if}

<!-- ---------------------------------------------------------------------- -->

<style>
  .error {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: var(--true-black-80);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 2%;
    z-index: 1000;
  }
  .error-overlay {
    position: fixed;
    top: 10%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--bright-red);
    color: white;
    padding: 3rem 4rem;
    border-radius: 8px;
    z-index: 999;
    max-width: 80%;
    text-align: center;
  }
  .reload-button {
    background: url(/buttons/btn_reset.svg) no-repeat;
    background-size: 40px 40px;
    width: 40px;
    height: 40px;
    border: none;
    float: center;
    cursor: pointer;
  }
  .reload-button:hover {
    background: url(/buttons/btn_reset_hover.svg) no-repeat;
    background-size: 40px 40px;
  }
  a {
    color: var(--white);
  }
</style>
